import React from 'react';
import SEOHead from './components/SEOHead';
import PDFRedactionComponent from './components/PDFRedactionComponent';
import SEOComponent from './components/SEOComponent';

function App() {
  return (
    <div className="App">
      <SEOHead />
      <header className="bg-gray-100 py-6">
        <div className="container mx-auto px-4">
          
          <h1 className="text-4xl font-bold text-gray-800"> ✨📄✂️ ai-redact.com 🔒</h1>
        </div>
      </header>
      <main>
        <section className="py-12 bg-white">
          <div className="container mx-auto px-4">
            <PDFRedactionComponent />
          </div>
        </section>
        <section className="py-12 bg-gray-50">
          <SEOComponent />
        </section>
      </main>
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Stepjump Solutions. All rights reserved.</p>
          <p className="mt-2">
            <a href="/privacy-policy" className="hover:underline">Privacy Policy</a> | 
            <a href="/terms-of-service" className="ml-4 hover:underline">Terms of Service</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;